var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import moment from 'moment';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, ButtonGroup, Col, Row } from 'reactstrap';
import Endpoints from '../../../../api/endpoints/endpoints';
import { useTitle } from '../../../../customHooks';
import { CommentCountLabel } from '../../../../shared/components/preActionItem/comments/commentCountLabel';
import DataTable from '../../../../shared/components/shared/Table/Table';
import { config as commonTableConfig } from '../../../../shared/config/preActionItem/dataTableConfig';
import { SystemTypeContext } from '../../../../shared/context/SystemTypeContext';
import { onChangeStatus, onChangeUrgencyFilter, onChangeUser, onChangeUserStatus, onChangeWatchingFilter, onRemove, onRemovePreAi, } from '../../../../shared/features/preActionItem';
import { exportToCsv, onChangeDateRangeFilter, onViewPreActionItem, } from '../../../../shared/features/shared';
import { reducer } from '../../../../shared/helper/reducer';
import { onPageChange, onPageSizeChange, onSearch, onSort, setLoader } from '../../../../shared/helper/table';
import User from '../../../../shared/helper/user';
import { useResetDataTableEffect } from '../../../../shared/hooks/useResetDataTableEffect';
import ExistingAiPreAiOnBedModal from '../../../../shared/modals/ExistingAiPreAiOnBed';
import { useExistingAiPreAiActions } from '../../../../shared/modals/ExistingAiPreAiOnBed/store/useExistingAiPreAiOnBedStore';
import { useConfirmModalActions } from '../../../../shared/store/global/useConfirmModalStore';
var tableFirstColumns = [
    {
        name: 'facility',
        title: 'Facility',
        sortable: true,
    },
    {
        name: 'area',
        title: 'Area',
        sortable: true,
    },
    {
        name: 'zone',
        title: 'Zone',
        sortable: true,
    },
];
var initialState = function () {
    var baseConfig = commonTableConfig(tableFirstColumns, false);
    return {
        config: __assign(__assign({}, baseConfig), { columns: __spreadArray(__spreadArray([], baseConfig.columns, true), [
                {
                    name: 'actions',
                    sortable: false,
                    component: function (row, callbacks) { return (_jsxs("div", { className: "d-flex", children: [_jsx(ButtonGroup, { className: "me-1", children: _jsxs(Button, { onClick: function () { return callbacks.onView(row.id); }, color: "primary", className: "button-ml position-relative", size: "sm", children: [parseInt(row.comment_count) ? _jsx(CommentCountLabel, { count: row.comment_count }) : '', "View"] }) }), User.can('managePreAi') && (_jsx(ButtonGroup, { className: "me-1", children: _jsx(Button, { onClick: function () { return callbacks.onRemove(row.id); }, color: "danger", size: "sm", children: "Remove" }) }))] })); },
                },
            ], false) }),
        selectedUserStatus: '',
        selectedStatuses: {
            1: '1',
        },
        selectedUserId: null,
    };
};
var fetch = function (state, dispatch, selectedFacility, increaseSeverity) {
    var config = state.config, selectedStatuses = state.selectedStatuses, selectedUserId = state.selectedUserId, selectedUserStatus = state.selectedUserStatus;
    var filtersData = config.filtersData;
    setLoader(state, dispatch, true);
    Endpoints.amazon
        .getList({
        query: {
            selected_user_id: selectedUserId,
            selected_statuses: selectedStatuses,
            selected_user_status: selectedUserStatus,
            selected_facility: selectedFacility,
            increase_severity: +increaseSeverity,
            pagination: config.pagination,
            sort: config.sort,
            search: config.search,
            urgency: filtersData.urgency,
            watching_filter: filtersData.watchingFilter,
            date_from: filtersData.dateRange[0]
                ? moment(filtersData.dateRange[0]).format('YYYY-MM-DD 00:00:00')
                : '',
            date_to: filtersData.dateRange[1]
                ? moment(filtersData.dateRange[1]).format('YYYY-MM-DD 00:00:00')
                : '',
        },
    })
        .then(function (resp) {
        if (resp) {
            dispatch({
                type: 'setState',
                state: {
                    config: __assign(__assign({}, config), { data: resp.data, loader: false, pagination: __assign(__assign({}, config.pagination), { total: resp.total }), filtersData: __assign(__assign({}, config.filtersData), { users: resp.users, stats: resp.last_stats }) }),
                },
            });
        }
    });
};
export var PreActionItemsList = function (_a) {
    var _b = _a.refreshFlag, refreshFlag = _b === void 0 ? null : _b, _c = _a.selectedFacility, selectedFacility = _c === void 0 ? null : _c;
    useTitle('Pre-Action List');
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _d = useReducer(reducer, initialState()), state = _d[0], dispatch = _d[1];
    var query = new URLSearchParams(window.location.search);
    var _e = useState(query.get('state') === 'increase'), increaseSeverity = _e[0], setIncreaseSeverity = _e[1];
    var config = state.config;
    useResetDataTableEffect([
        selectedFacility,
        state.selectedStatuses,
        state.selectedUserId,
        config.filtersData.watchingFilter,
        state.selectedUserStatus,
        config.filtersData.dateRange,
        config.filtersData.urgency,
    ], state, dispatch);
    useEffect(function () {
        fetch(state, dispatch, selectedFacility, increaseSeverity);
    }, [
        refreshFlag,
        selectedFacility,
        state.selectedStatuses,
        state.selectedUserId,
        config.filtersData.watchingFilter,
        state.selectedUserStatus,
        increaseSeverity,
        config.filtersData.dateRange,
        config.filtersData.urgency,
    ]);
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var _f = useExistingAiPreAiActions(), setIsVisible = _f.setIsVisible, setInstallationPointId = _f.setInstallationPointId;
    return (_jsxs(Row, { children: [_jsxs(Col, { children: [_jsxs("h3", { className: "page-title", children: ["Pre-Action List", config.data.length > 0 ? (_jsx(Button, { color: "success", className: "float-end", size: "sm", onClick: function () {
                                    return exportToCsv(Endpoints.amazon.getPreActionListCsv, 'amazon-pre-action-items', {
                                        query: {
                                            selected_user_id: state.selectedUserId,
                                            selected_statuses: state.selectedStatuses,
                                            selected_user_status: state.selectedUserStatus,
                                            selected_facility: selectedFacility,
                                            increase_severity: +increaseSeverity,
                                            sort: config.sort,
                                            search: config.search,
                                            watching_filter: config.filtersData.watchingFilter,
                                            urgency: config.filtersData.urgency,
                                            date_from: config.filtersData.dateRange[0]
                                                ? moment(config.filtersData.dateRange[0]).format('YYYY-MM-DD 00:00:00')
                                                : '',
                                            date_to: config.filtersData.dateRange[1]
                                                ? moment(config.filtersData.dateRange[1]).format('YYYY-MM-DD 00:00:00')
                                                : '',
                                        },
                                        isBlob: true,
                                    });
                                }, children: "Export to CSV" })) : (_jsx(_Fragment, {}))] }), _jsx("div", { className: "table_wrap", children: _jsx(DataTable, { config: config, onPageChange: function (page) {
                                return onPageChange(page, state, dispatch, function () {
                                    return fetch(state, dispatch, selectedFacility, increaseSeverity);
                                });
                            }, onPageSizeChange: function (pageSize) {
                                return onPageSizeChange(pageSize, state, dispatch, function () {
                                    return fetch(state, dispatch, selectedFacility, increaseSeverity);
                                });
                            }, onSort: function (column_name, ev) {
                                return onSort(column_name, state, dispatch, function () { return fetch(state, dispatch, selectedFacility, increaseSeverity); }, ev);
                            }, callbacks: {
                                onChangeStatus: function (status) { return onChangeStatus(state, dispatch, status); },
                                onRemove: function (id) {
                                    return onRemove(state, dispatch, setConfirmData, function () {
                                        return onRemovePreAi(function () { return Endpoints.amazon.removePreActionItem(id); }, function () { return fetch(state, dispatch, selectedFacility, increaseSeverity); });
                                    });
                                },
                                onChangeUser: function (id) { return onChangeUser(state, dispatch, id); },
                                onChangeWatchingFilter: function (filter) { return onChangeWatchingFilter(state, dispatch, filter); },
                                onChangeUrgencyFilter: function (filter) { return onChangeUrgencyFilter(state, dispatch, filter); },
                                onView: function (id) { return onViewPreActionItem(id, selectedSystemType); },
                                viewEquipmentPreAi: function (installationPointId) {
                                    setIsVisible(true);
                                    setInstallationPointId(installationPointId);
                                },
                                onChangeUserStatus: function (userStatus) { return onChangeUserStatus(state, dispatch, userStatus); },
                                onIncreaseSeverity: function () { return setIncreaseSeverity(!increaseSeverity); },
                                onChangeDateRange: function (range) { return onChangeDateRangeFilter(state, dispatch, range); },
                            }, onSearch: function (search) {
                                return onSearch(search, state, dispatch, function () {
                                    return fetch(state, dispatch, selectedFacility, increaseSeverity);
                                });
                            } }) })] }), _jsx(ExistingAiPreAiOnBedModal, { isPreAi: true })] }));
};
